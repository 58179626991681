import styled from 'styled-components';
import { SIDE_PADDING } from '../../../utils/sizes';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Container = styled.div`
  position: relative;
  background-color: ${COLORS.BLUE};
  padding-top: 45px;
  padding-bottom: 34px;
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};
  z-index: 1;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 60px 16px;
  }
`;

export const TextureImg = styled.img`
  position: absolute;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  width: 380px;
  z-index: -1;
`;
