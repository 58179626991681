import * as React from 'react';

import { Container, Title, TagContainer, Tag, Description, LinkToDetail } from './styles';

import Markdown from '../../Markdown';

const FeaturedTool = ({ tool, negative, small }) => {
  return (
    <LinkToDetail to={`/tools/${tool._id}`}>
      <Container negative={negative} small={small}>
        <Title negative={negative} small={small}>
          {tool?.title}
        </Title>
        <TagContainer>
          {tool?.tool_tags?.map((tag) => (
            <Tag>{tag?.name}</Tag>
          ))}
        </TagContainer>
        <Description negative={negative} small={small}>
          <Markdown>{tool?.short_description}</Markdown>
        </Description>
      </Container>
    </LinkToDetail>
  );
};

export default FeaturedTool;
