import styled from 'styled-components';
import { Link } from 'gatsby';

import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const Container = styled.div`
  background-color: ${(props) => (props.negative ? COLORS.BLACK : COLORS.BACKGROUND_LIGHT)};
  min-width: 280px;
  max-width: 330px;
  height: ${(props) => (props.small ? '405px' : '580px')};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${COLORS.BLACK};
  cursor: pointer;
  overflow-y: auto;
  &:hover {
    background-color: ${COLORS.BLACK};
    h5 {
      color: ${COLORS.WHITE};
    }
    & > div:last-child > div {
      color: ${COLORS.WHITE};
    }
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 215px;
    min-width: 215px;
    height: 350px;
  }
`;

export const Title = styled.h5`
  font-size: ${(props) => (props.small ? '34px' : '50px')};
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
  color: ${(props) => (props.negative ? COLORS.WHITE : COLORS.BLACK)};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.19px;
  }
`;

export const Description = styled.div`
  font-size: ${(props) => (props.small ? '16px' : '20px')};
  line-height: 30px;
  color: ${(props) => (props.negative ? COLORS.WHITE : COLORS.BLACK)};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-bottom: 0;
  }
`;

export const Tag = styled.div`
  background-color: ${COLORS.LIGHT_GREY};
  border-radius: 5px;
  font-size: 16px;
  margin-right: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: ${COLORS.BLACK};
`;

export const LinkToDetail = styled(Link)`
  text-decoration: none;
  color: none;
  backgound-color: black;
`;
