import styled from 'styled-components';
import { SIDE_PADDING } from '../../../utils/sizes';
import { COLORS, BREAKPOINTS, H6 } from '../../../utils/theme';

import IconPlus from '../../IconPlus';

export const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: ${COLORS.BACKGROUND_LIGHT_2};
  padding-top: 45px;
  padding-bottom: 34px;
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0;
  }
`;

export const FeaturedTools = styled.div`
  max-width: calc(100% - 120px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    justify-content: center;
    max-width: 100%;
  }
`;

export const Plus = styled(IconPlus)`
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1rem;
`;

export const MoreLoadRow = styled.button`
  cursor: pointer;
  display: inline-flex;
  max-width: max-content;
  align-items: center;
  padding: 0rem 1.7rem;
  border-radius: 3rem;
  margin-top: 32px;

  background: none;

  margin-left: 1rem;

  border: 1.5px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};

  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }
  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 0
  }
`;

export const MoreLoadText = styled(H6)`
  margin-left: 15px;
  color: ${COLORS.BLUE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;

export const Title = styled.h3`
  font-family: 'Hellix';
  font-weight: 400;
  font-size: 16px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 20px;
  position: relative;
  z-index: 2;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 0;
  }
`;

export const TextureImg = styled.img`
  position: absolute;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  width: 380px;
  z-index: -1;
`;
