import * as React from 'react';

import FeaturedTool from '../FeaturedTool';

import { Logo, MenuButton } from '../../HomeHeader/styles';

import {
  Container,
  FeaturedTools,
  MoreLoadRow,
  Plus,
  MoreLoadText,
  Title,
  TextureImg,
} from './styles';
import textureImgBlue2 from '../../../images/textures/blue-2.png';
import textureImgYellow1 from '../../../images/textures/yellow-1.png';
const SLICE_STEP = 9;

const Tools = ({ tools = [], title, menuIsShown }) => {
  const [loadMoreSlice, setLoadMoreSlice] = React.useState(SLICE_STEP);

  const handleLoadMore = () => setLoadMoreSlice((prevSlice) => prevSlice + SLICE_STEP);

  const slicedTools = tools.slice(0, loadMoreSlice);

  const hasMoreTools = loadMoreSlice < tools.length;

  return (
    <Container>
      <Logo visible />
      <MenuButton visible menuIsShown={menuIsShown} />
      {title && <Title>{title}</Title>}
      <FeaturedTools>
        {slicedTools.map((tool, index) => (
          <FeaturedTool key={index} small tool={tool} />
        ))}
        <TextureImg src={textureImgBlue2} top="-20%" left="10%" />
        <TextureImg src={textureImgYellow1} top="60%" left="60%" />
      </FeaturedTools>
      {hasMoreTools && (
        <MoreLoadRow onClick={handleLoadMore}>
          <Plus />
          <MoreLoadText>Load more tools</MoreLoadText>
        </MoreLoadRow>
      )}
    </Container>
  );
};

export default Tools;
